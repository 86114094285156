import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { QaSectionCardProps } from './models';

import './QaSectionCard.scss';

const QaSectionCard: FC<QaSectionCardProps> = ({ QaSectionCard: { header, content } }) => (
  <div className="qa-section-card">
    <DangerouslySetInnerHtml html={header} className="qa-section-card__header" />
    <DangerouslySetInnerHtml html={content} />
  </div>
);

export default QaSectionCard;
