import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import QaSection from 'components/QaSection';
import SingleLineBanner from 'components/SingleLineBanner';
import TopBanner from 'components/TopBanner';
import WomenTestimonies from 'components/WomenTestimonies';

const VaginalDrynessPage: FC<VaginalDrynessPageTypes.VaginalDrynessPageProps> = ({
  data: {
    vaginalDrynessPage: {
      urls,
      seo,
      topBanner,
      singleLineBanner,
      womenTestimoniesSection,
      qaSection,
    },
  },
}) => (
  <Layout headerTransparent seo={seo} urls={urls}>
    <TopBanner topBanner={topBanner} />
    <QaSection qaSection={qaSection} />
    <SingleLineBanner singleLineBanner={singleLineBanner} />
    {womenTestimoniesSection ? (
      <WomenTestimonies WomenTestimoniesSection={womenTestimoniesSection} />
    ) : null}
  </Layout>
);

export const query = graphql`
  query ($lang: String) {
    vaginalDrynessPage(lang: { eq: $lang }) {
      urls {
        ...UrlsFragment
      }
      seo {
        ...SEOStructureFragment
      }
      topBanner {
        ...TopBannerFragment
      }
      womenTestimoniesSection {
        ...WomenTestimoniesSectionFragment
      }
      singleLineBanner {
        ...SingleLineBannerFragment
      }
      qaSection {
        ...QASectionFragment
      }
    }
  }
`;

export default VaginalDrynessPage;
