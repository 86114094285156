import React, { FC } from 'react';
import { useId } from 'react-id-generator';

import TestimonyCard from 'components/TestimonyCard';

import { WomenTestimoniesProps } from './models';

import './WomenTestimonies.scss';

const WomenTestimonies: FC<WomenTestimoniesProps> = ({
  WomenTestimoniesSection: { title, subtitle, womenTestimoniesPicker, readMore, readLess },
}) => {
  const cardId = useId(womenTestimoniesPicker.length);
  const readMoreButtonText = { readMore, readLess };

  return (
    <div className="women-testimonies">
      <div className="women-testimonies__text">
        <h2 className="women-testimonies__title">{title}</h2>
        <h3 className="women-testimonies__subtitle">{subtitle}</h3>
      </div>
      <div className="women-testimonies__cards">
        {womenTestimoniesPicker?.map((card, index) => (
          <TestimonyCard
            womenTestimoniesPicker={card}
            key={cardId[index]}
            readMoreButton={readMoreButtonText}
          />
        ))}
      </div>
    </div>
  );
};

export default WomenTestimonies;
