import React, { FC, useContext, useState } from 'react';
import { useId } from 'react-id-generator';
import { ScreenRecognitionContext } from '@phx-husky/screen-recognition';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import { VisualType, VisualVariant } from 'common/Button/enums';
import SvgImageIcon from 'common/SvgImageIcon';

import { TestimonyTextContentProps } from './models';

import './TestimonyCardTextContent.scss';

const TestimonyCardTextContent: FC<TestimonyTextContentProps> = ({
  readMoreButton: { readLess, readMore },
  testimonyTextContent: {
    quote,
    testimonies,
    cardTheme: { label },
    quotationMark,
  },
}) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const [isFirstTimeOpen, setIsFirstTimeOpen] = useState(true);

  const { isDesktop, isXl } = useContext(ScreenRecognitionContext);

  const colorTheme = classNames(`${label?.toLowerCase()}-theme`);
  const buttonColor = isReadMore
    ? VisualVariant.ReversedBlack
    : VisualVariant[label] ?? VisualVariant.Pink;

  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
    setIsFirstTimeOpen(false);
  };

  const testimonyId = useId(testimonies.length);
  const arrayOfTestimonies = isDesktop || isXl ? testimonies : testimonies.slice(0, 1);

  const { altText } = quotationMark;

  return (
    <div className="testimony-text-content">
      <div
        className={classNames(
          'testimony-text-content__quote-wrapper',
          `testimony-text-content__quote-wrapper--${colorTheme}`
        )}
      >
        <div className="testimony-text-content__quotation-mark-wrapper">
          <SvgImageIcon
            icon={quotationMark}
            iconAlt={altText}
            customClassName="testimony-text-content__quotation-mark"
          />
        </div>
        <div className="testimony-text-content__quote">{quote}</div>
      </div>
      <div className="testimony-text-content__testimonies">
        {arrayOfTestimonies.map(({ testimony }, index) => (
          <DangerouslySetInnerHtml
            key={testimonyId[index]}
            html={testimony}
            className={classNames(
              'testimony-text-content__story',
              { 'testimony-text-content__first-open': isFirstTimeOpen },
              !isFirstTimeOpen && isReadMore
                ? 'testimony-text-content__read-more'
                : 'testimony-text-content__read-less'
            )}
          />
        ))}
      </div>
      {isDesktop || isXl ? (
        <Button
          visualType={VisualType.Squared}
          visualVariant={buttonColor}
          ariaLabel={readMore}
          onClick={handleReadMore}
        >
          {isReadMore ? readLess : readMore}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

export default TestimonyCardTextContent;
