import React, { FC } from 'react';
import { useId } from 'react-id-generator';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import QaSectionCard from '../QaSectionCard';
import { QaSectionProps } from './models';

import './QaSection.scss';

const QaSection: FC<QaSectionProps> = ({
  qaSection: {
    footnotes,
    questionCards,
    qaHeader,
    qaSubheading,
    subtitle,
    title,
    backgroundImage,
    backgroundImageAltText,
  },
}) => {
  const idList = useId(questionCards.length);

  return (
    <div className="qa-section">
      <div className="qa-section__wrapper">
        <div className="qa-section__image-wrapper">
          <Image
            imageData={backgroundImage}
            alt={backgroundImageAltText}
            className="qa-section__image"
          />
          <div className="qa-section__title-wrapper">
            <h2>{title}</h2>
            <DangerouslySetInnerHtml html={subtitle} className="qa-section__subtitle" />
          </div>
        </div>
        <div className="qa-section__header-wrapper">
          <h2>{qaHeader}</h2>
          <p>{qaSubheading}</p>
        </div>
        <div className="qa-section__card-wrapper">
          {questionCards.map((questionCard, index) => (
            <QaSectionCard QaSectionCard={questionCard} key={idList[index]} />
          ))}
          <DangerouslySetInnerHtml className="qa-section__footnotes" html={footnotes} />
        </div>
      </div>
    </div>
  );
};

export default QaSection;
