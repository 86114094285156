import React, { FC } from 'react';
import classNames from 'classnames';

import Image from 'common/Image/Image';
import TestimonyCardTextContent from 'components/TestimonyCardTextContent';

import { TestimonyCardProps } from './models';

import './TestimonyCard.scss';

const TestimonyCard: FC<TestimonyCardProps> = ({ womenTestimoniesPicker, readMoreButton }) => {
  const {
    title,
    signature,
    image,
    cardTheme: { label },
  } = womenTestimoniesPicker;
  const colorTheme = classNames(`${label.toLowerCase()}-theme`);

  return (
    <div className="testimony">
      <div
        className={classNames(
          'testimony__image-wrapper',
          `testimony__image-wrapper--${colorTheme}`
        )}
      >
        <Image imageData={image} alt={signature} />
        <div className="testimony__image-text">
          <div className="testimony__title">{title}</div>
          <div className="testimony__signature">{signature}</div>
        </div>
      </div>
      <TestimonyCardTextContent
        testimonyTextContent={womenTestimoniesPicker}
        readMoreButton={readMoreButton}
      />
    </div>
  );
};

export default TestimonyCard;
